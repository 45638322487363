import { v1SitesSubscribeToNewsletterCreate } from '@on3/api';
import { externalApi } from '@on3/ui-lib/index';
import Cookies from 'universal-cookie';

interface IPostMessageProps {
  iframeId: string;
  success: boolean;
  message: string;
  object: unknown;
}

interface IPianoSetCookieProps {
  name: string;
  value: string;
}

interface ISubscribeNewsletterProps {
  email: string;
  newsletterSiteKey: number;
  campaign: string;
  campaignSource: string;
}

const cookies = new Cookies();

export const sendPostMessageToPiano = ({
  iframeId,
  success,
  message,
  object,
}: IPostMessageProps) => {
  const iframe = document.querySelectorAll('#' + iframeId);

  if (iframe && iframe?.length > 0) {
    if (iframe[0] instanceof HTMLIFrameElement) {
      iframe[0].contentWindow?.postMessage(
        {
          piano: {
            success,
            message,
            object,
          },
        },
        '*',
      );
    }
  }
};

export const setPianoCustomVariableCookie = ({
  name,
  value,
}: IPianoSetCookieProps) => {
  let cookieValue = cookies.get('__pcvc');

  if (typeof cookieValue === 'string') {
    cookieValue = JSON.parse(cookieValue);
  }

  if (typeof cookieValue !== 'object' || cookieValue === null) {
    cookieValue = {};
  }

  if (!cookieValue[name] || cookieValue[name] !== value) {
    cookieValue[name] = value;
    const expires = new Date();

    expires.setTime(expires.getTime() + 94608000000);

    cookies.set('__pcvc', JSON.stringify(cookieValue), { expires, path: '/' });
  }
};

export const readPianoCustomVariableCookie = () => {
  let cookieValue = cookies.get('__pcvc');

  if (typeof cookieValue === 'string') {
    cookieValue = JSON.parse(cookieValue);
  }

  if (typeof cookieValue !== 'object' || cookieValue === null) {
    cookieValue = {};
  }

  return cookieValue;
};

export const subscribeToNewsletter = ({
  email,
  newsletterSiteKey,
  campaign,
  campaignSource,
}: ISubscribeNewsletterProps) => {
  try {
    v1SitesSubscribeToNewsletterCreate(externalApi, newsletterSiteKey, {
      email,
      campaign,
      campaignSource,
      requireConfirmation: true,
    });
  } catch (e) {
    console.error('There was an error subscribing to the newsletter.');
  }
};
